import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miscellaneous-main',
  templateUrl: './miscellaneous-main.component.html',
})
export class MiscellaneousMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
