import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thorheyerdahl',
  templateUrl: './thorheyerdahl.component.html',
  styleUrls: ['./thorheyerdahl.component.css']
})
export class ThorheyerdahlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
