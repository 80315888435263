import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explorers-main',
  templateUrl: './explorers-main.component.html'
})
export class ExplorersMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
