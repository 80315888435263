import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';

import { HomeComponent } from './home/home.component';
import { ArchiveComponent } from './archive/archive.component';
import { ContactComponent } from './contact/contact.component';

import { ExplorersComponent } from './explorers/explorers.component';
import { ExplorersMainComponent } from './explorers/explorers-main/explorers-main.component';
import { ThorheyerdahlComponent } from './explorers/thorheyerdahl/thorheyerdahl.component';

import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { MiscellaneousMainComponent } from './miscellaneous/miscellaneous-main/miscellaneous-main.component';
import { LaunchComponent } from './miscellaneous/launch/launch.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    SideNavComponent,
    ExplorersComponent,
    ExplorersMainComponent,
    ThorheyerdahlComponent,
    MiscellaneousComponent,
    MiscellaneousMainComponent,
    ArchiveComponent,
    LaunchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
