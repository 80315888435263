import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SideNavComponent } from './side-nav';
import { HomeComponent } from './home';
import { ArchiveComponent } from './archive/archive.component';
import { ContactComponent } from './contact';

import { ExplorersComponent } from './explorers/explorers.component';
import { ExplorersMainComponent } from './explorers/explorers-main/explorers-main.component';
import { ThorheyerdahlComponent } from './explorers/thorheyerdahl/thorheyerdahl.component';

import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { MiscellaneousMainComponent } from './miscellaneous/miscellaneous-main/miscellaneous-main.component';
import { LaunchComponent } from './miscellaneous/launch/launch.component';


const routes: Routes = [
  { path: '', outlet: 'side-nav', component: SideNavComponent},
  { path: ':id', outlet: 'side-nav', component: SideNavComponent},

  { path: '', component: HomeComponent },
  { path: 'archive', component: ArchiveComponent},
  { path: 'contact', component: ContactComponent},

  {
    path: 'explorers',
    component: ExplorersComponent,
    children: [
      {path: '', component:ExplorersMainComponent},
      {path: 'thorheyerdahl', component:ThorheyerdahlComponent}
    ]
  },

  { 
    path: 'miscellaneous', 
    component: MiscellaneousComponent, 
    children: [
      { path: '', component: MiscellaneousMainComponent},
      { path: 'launch', component: LaunchComponent}
    ]},

  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
